<template>
	<!-- Table Container Card -->
	<b-card no-body>
		<div class="m-2">
			<!-- Table Top -->
			<b-row>
				<!-- Date picker -->
				<b-col cols="12" md="6">
					<b-row>
						<b-col md="5 p-0">
							<label for="example-input">From Date</label>
							<b-form-datepicker
								id="from-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								v-model="search_filter.from_date"
							/>
						</b-col>
						<b-col md="5 p-0 ml-1 mr-1">
							<label for="example-input">To Date</label>
							<b-form-datepicker
								id="to-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								v-model="search_filter.to_date"
							/>
						</b-col>
						<b-col md="1 p-0 d-flex align-items-end">
							<b-button @click="getVaccinationSchedule()" variant="primary "> Filter </b-button>
						</b-col>
					</b-row>
				</b-col>
				<!-- Search -->

				<b-col cols="12" md="6" class="pl-5 mt-2 d-flex justify-content-end align-items-center">
					<div>
						<b-form-input
							type="text"
							class="form-control"
							placeholder="Search"
							@input="getVaccinationSchedule()"
							v-model.trim="search_filter.input_field"
							style="border-radius: 4px"
						/>
					</div>
					<b-button
						@click="clearFilter"
						variant="secondary
 			 ml-1"
					>
						Clear
					</b-button>
					<b-button v-b-modal.add_vaccination_modal variant="primary mr-1 ml-1"> Add </b-button>
					<b-button @click="excelDownload()" variant="warning">
						<feather-icon icon="DownloadIcon" size="16" />
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-if="vaccinationSchedule && vaccinationSchedule.total > 0">
			<b-table
				:items="vaccinationSchedule.data"
				responsive
				:fields="fields"
				empty-text="No matching records found"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ vaccinationSchedule.from + data.index }}
					</div>
				</template>
				<template #cell(production_type)="data">
					<span v-if="data.item.production_type">{{ data.item.production_type.name }}</span>
					<span v-else>Na</span>
				</template>
				<template #cell(vaccine)="data">
					<span v-if="data.item.vaccine">{{ data.item.vaccine.name }}</span>
					<span v-else>Na</span>
				</template>
				<template #cell(vaccination_status)="data">
					<div v-if="data.value === 'Active'" class="activeClass">Active</div>
					<div v-else class="inactiveClass">Inactive</div>
				</template>

				<template #cell(actions)="data">
					<div class="text-nowrap">
						<feather-icon
							icon="EditIcon"
							size="16"
							v-b-modal.edit-vaccination-schedule
							@click="() => getVaccinationById(data.item.id)"
						/>

						<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />

						<!-- <feather-icon
              @click="() => setVaccinationId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-delete-vaccination
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
					</div>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-pagination
					:value="vaccinationSchedule.current_page"
					:total-rows="vaccinationSchedule.total"
					:per-page="vaccinationSchedule.per_page"
					align="right"
					@change="getVaccinationSchedule"
				>
					<template #prev-text>
						<feather-icon icon="ChevronLeftIcon" size="18" />
					</template>
					<template #next-text>
						<feather-icon icon="ChevronRightIcon" size="18" />
					</template>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">No vaccine schedule added yet</h4>
		</div>
		<!-- Add Vacccination Modal -->
		<b-modal
			id="add_vaccination_modal"
			ok-only
			ok-title="Submit"
			@ok="addVaccinationSchedule"
			ref="my-modal"
			title="Add Vaccination Schedule"
			@show="resetModal"
			@hidden="resetModal"
			:no-close-on-backdrop="true"
		>
			<validation-observer ref="simpleRules">
				<b-form ref="form" @submit="addVaccinationSchedule">
					<b-form-group>
						<template v-slot:label> Production Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Production Type" rules="required">
							<v-select
								v-model="vaccinationScheduleData.production_type_id"
								:options="productionTypes"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Vaccination Name <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Vaccination Name" rules="required">
							<v-select
								v-model="vaccinationScheduleData.vaccine_id"
								:options="vaccineList"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter From Day<span class="text-danger">*</span> </template>
						<validation-provider
							#default="{ errors }"
							name="From Day"
							:rules="{
								required: true,
								integer: true,
								min_value: 1,
								max_value: vaccinationScheduleData.to_day || 400,
							}"
						>
							<b-form-input name="from_day" v-model="vaccinationScheduleData.from_day" placeholder="Enter From Day" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter To Day<span class="text-danger">*</span> </template>
						<validation-provider
							#default="{ errors }"
							name="To Day"
							:rules="{
								required: true,
								integer: true,
								min_value: vaccinationScheduleData.from_day,
								max_value: 400,
							}"
						>
							<b-form-input name="to_day" v-model="vaccinationScheduleData.to_day" placeholder="Enter To Day" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>

		<!-- edit vaccinationSchedule -->
		<b-modal
			id="edit-vaccination-schedule"
			ok-only
			ok-title="Submit"
			@ok="updateVaccination"
			ref="my-modal"
			title="Edit Vaccination Schedule"
		>
			<validation-observer ref="simpleRules">
				<b-form ref="form" @submit="updateVaccination">
					<b-form class="" style="color: red">These changes would apply only to new flock registration. </b-form><br />
					<b-form-group>
						<template v-slot:label> Production Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Production Type" rules="required">
							<v-select
								v-model="editVaccinationData.production_type_id"
								:options="productionTypes"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
								disabled
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Vaccine Name <span class="text-danger">*</span> </template>
						<div class="form-label-group">
							<validation-provider #default="{ errors }" name="Vaccination Name" rules="required">
								<v-select
									v-model="editVaccinationData.vaccine_id"
									:options="vaccineList"
									label="name"
									:clearable="false"
									:reduce="(e) => e.id"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</div>
					</b-form-group>

					<b-form-group>
						<template v-slot:label> Enter From Day<span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="From Day" rules="required">
							<b-form-input name="from_day" v-model="editVaccinationData.from_day" placeholder="" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter To Day<span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="To Day" rules="required">
							<b-form-input name="to_day" v-model="editVaccinationData.to_day" placeholder="" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group label="Status" label-for=" status">
						<div class="d-flex mt-1">
							<b-form-radio v-model="editVaccinationData.vaccination_status" name="status" value="Active" class="mr-1"
								>Active</b-form-radio
							>
							<b-form-radio v-model="editVaccinationData.vaccination_status" name="status" value="Inactive"
								>InActive</b-form-radio
							>
						</div>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
		<!-- delete modal -->
		<DeleteModal id="modal-delete-vaccination" :onClicked="deleteVaccinationSchedule" />
	</b-card>
</template>

<script>
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, email, min_value, max_value, integer } from "@validations";

export default {
	components: {
		DeleteModal,
	},
	data: () => ({
		value: "",
		// perPage: 5,
		// currentPage: 1,
		// selected: null,
		// isSortDirDesc: null,
		// searchQuery: null,
		// sortBy: null,
		productionTypes: [],
		vaccineList: [],
		vaccinationSchedule: {},
		vaccinationScheduleData: {
			production_type_id: null,
			vaccine_id: null,
			name: null,
			from_day: null,
			to_day: null,
		},
		search_filter: {
			input_field: "",
			to_date: "",
			from_date: "",
		},
		editVaccinationData: {
			from_day: null,
			to_day: null,
			production_type_id: null,
			vaccine_id: null,
		},
		fields: [
			{ key: "index", label: "No" },
			{ key: "production_type" },
			{
				key: "vaccine",
				label: "vaccine",
			},
			"from_day",
			"to_day",
			{
				key: "created_at",
				label: "created at",
				formatter: (value) => {
					return moment(value).format("MMMM Do YYYY");
				},
			},
			{ key: "vaccination_status", label: "Status" },
			"Actions",
		],
	}),
	methods: {
		getVaccinationSchedule(pageNo = 1) {
			const companyId = this.$route.params.companyId;
			let url = `web/vaccination-schedule`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.vaccinationSchedule = response.data.vaccinationScheduleList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		setVaccinationId(id) {
			this.editVaccinationData.id = id;
		},
		getVaccinationById(id) {
			axiosIns
				.get(`web/vaccination-schedule/${id}`)
				.then((response) => {
					this.editVaccinationData = response.data.vaccinationSchedule;
					this.editVaccinationData.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		updateVaccination(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				var formData = new FormData();
				formData.append("_method", "PUT");
				formData.append("vaccine_id", this.editVaccinationData.vaccine_id);
				formData.append("vaccination_status", this.editVaccinationData.vaccination_status);
				formData.append("production_type_id", this.editVaccinationData.production_type_id);
				axiosIns
					.post(`web/vaccination-schedule/${this.editVaccinationData.id}`, formData)
					.then((response) => {
						this.$toast({
							component: ToastificationContent,
							position: "top-right",
							props: {
								icon: "CoffeeIcon",
								variant: "success",
								text: `Vaccination Schedule updated `,
							},
						});
						this.$refs["my-modal"].hide();
						this.getVaccinationSchedule();
					})
					.catch((error) => {
						if (error.response.status == 400) {
							var data = error.response.data;
						} else {
							var data = error.response.data.errors;
						}
						let arr = Object.values(data).flat().toString(",");

						this.$toast({
							component: ToastificationContent,
							position: "top-right",
							props: {
								icon: "CoffeeIcon",
								variant: "danger",
								text: arr,
							},
						});
						this.$refs["my-modal"].hide();
					});
			});
		},
		addVaccinationSchedule(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					let formData = new FormData();
					// this.vaccinationScheduleData.farm_id = this.$route.params.farmId;
					// this.vaccinationScheduleData.company_id =
					//   this.$route.params.companyId;
					let form_data = this.objectToFormData(this.vaccinationScheduleData, formData);

					axiosIns
						.post(`web/vaccination-schedule`, form_data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.getVaccinationSchedule();
							this.$refs["my-modal"].hide();
						})
						.catch((error) => {
							if (error.response.status == 400) {
								var data = error.response.data;
							} else {
								var data = error.response.data.errors;
							}
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},

		deleteVaccinationSchedule(id) {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/vaccination-schedule/${this.editVaccinationData.id}`, data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `Data Successfully Deleted `,
						},
					});
					this.$refs["my-modal"].hide();
					this.getVaccinationSchedule();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.$refs["my-modal"].hide();
					this.getVaccinationSchedule();
				});
		},
		getSelectedVaccine(name) {
			this.vaccinationScheduleData.name = name;
		},
		getProductionTypes() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getVaccineNameList() {
			axiosIns
				.get(`web/get-all-vaccine`)
				.then((response) => {
					this.vaccineList = response.data.vaccine_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-vaccination-schedule`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getVaccinationSchedule();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getVaccinationSchedule();
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getVaccinationSchedule();
		},
		resetModal() {
			this.vaccinationScheduleData.production_type_id = null;
			this.vaccinationScheduleData.vaccine_id = null;
			this.vaccinationScheduleData.from_day = null;
			this.vaccinationScheduleData.to_day = null;
		},
	},

	created() {
		this.getVaccinationSchedule();
		this.getVaccineNameList();
		this.getProductionTypes();
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
.activeClass {
	background-color: #28c76f;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	border-radius: 10px;
	justify-content: center;
}
.inactiveClass {
	background-color: #ea5455;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	justify-content: center;
	border-radius: 10px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	// ::v-deep .vs__selected-options {
	// 	flex-wrap: nowrap;
	// }

	::v-deep .vs__selected {
		width: 100px;
	}
}
.v-select .dropdown-toggle .clear {
	display: none;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
