<template>
	<!-- Table Container Card -->
	<b-card no-body>
		<div class="m-2">
			<!-- Table Top -->
			<b-row>
				<!-- Date picker -->
				<b-col cols="12 pr-2" md="6">
					<b-row>
						<b-col md="5 p-0">
							<label for="example-input">From Date</label>
							<b-form-datepicker
								id="from-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								v-model="search_filter.from_date"
							/>
						</b-col>
						<b-col md="5 p-0 ml-1 mr-1">
							<label for="example-input">To Date</label>
							<b-form-datepicker
								id="to-date"
								:date-format-options="{
									year: 'numeric',
									month: 'short',
									day: '2-digit',
									weekday: 'short',
								}"
								placeholder="Choose a date"
								v-model="search_filter.to_date"
							/>
						</b-col>
						<b-col md="1 p-0 d-flex align-items-end">
							<b-button @click="getMedicineSchedules()" variant="primary "> Filter </b-button>
						</b-col>
					</b-row>
				</b-col>
				<!-- Search -->

				<b-col cols="12" md="6" class="pl-5 mt-2 d-flex justify-content-end align-items-center">
					<div>
						<b-form-input
							type="text"
							class="form-control"
							placeholder="Search"
							@input="getMedicineSchedules()"
							v-model.trim="search_filter.input_field"
							style="border-radius: 4px"
						/>
					</div>
					<b-button
						@click="clearFilter"
						variant="secondary
 			 ml-1"
					>
						Clear
					</b-button>
					<b-button v-b-modal.add_medicine_modal variant="primary mr-1 ml-1"> Add </b-button>
					<b-button @click="excelDownload()" variant="warning">
						<feather-icon icon="DownloadIcon" size="16" />
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-if="medicineSchedules && medicineSchedules.total > 0">
			<b-table
				:items="medicineSchedules.data"
				responsive
				:fields="fields"
				empty-text="No matching records found"
				class="position-relative"
			>
				<template #cell(index)="data">
					<div class="ml-1">
						{{ medicineSchedules.from + data.index }}
					</div>
				</template>
				<template #cell(status)="data">
					<div v-if="data.value === 'Active'" class="activeClass">Active</div>
					<div v-else class="inactiveClass">Inactive</div>
				</template>
				<template #cell(route)="data">
					<div class="ml-1">
						{{ data.item.route.name }}
					</div>
				</template>
				<template #cell(production_type)="data">
					<span v-if="data.item.production_type">{{ data.item.production_type.name }}</span>
					<span v-else>NA</span>
				</template>
				<template #cell(medicine)="data">
					<span v-if="data.item.medicine">{{ data.item.medicine.name }}</span>
					<span v-else>NA</span>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="text-nowrap">
						<feather-icon
							icon="EditIcon"
							size="16"
							v-b-modal.edit-medicine-schedule
							@click="() => getMedicineById(data.item.id)"
						/>

						<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />

						<!-- <feather-icon
              @click="() => setMedicineId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-delete-medicine
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
					</div>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-pagination
					:value="medicineSchedules.current_page"
					:total-rows="medicineSchedules.total"
					:per-page="medicineSchedules.per_page"
					align="right"
					@input="getMedicineSchedules"
				>
					<template #prev-text>
						<feather-icon icon="ChevronLeftIcon" size="18" />
					</template>
					<template #next-text>
						<feather-icon icon="ChevronRightIcon" size="18" />
					</template>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">No Medicine schedule added yet</h4>
		</div>
		<!-- Add Medicine Modal -->
		<b-modal
			id="add_medicine_modal"
			ok-only
			ok-title="Submit"
			@ok="addMedicineSchedule"
			ref="my-modal"
			title="Add Medicine Schedule"
			@show="resetModal"
			@hidden="resetModal"
			:no-close-on-backdrop="true"
		>
			<validation-observer ref="simpleRules">
				<b-form ref="form" @submit="addMedicineSchedule">
					<b-form-group>
						<template v-slot:label> Production Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Production Type" rules="required">
							<v-select
								v-model="medicineScheduleData.production_type_id"
								:options="productionTypes"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter From Day<span class="text-danger">*</span> </template>
						<validation-provider
							#default="{ errors }"
							name="From Day"
							:rules="{
								required: true,
								integer: true,
								min_value: 1,
							}"
						>
							<b-form-input name="from_day" v-model="medicineScheduleData.from_day" placeholder="Enter From Day" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter To Day<span class="text-danger">*</span> </template>
						<validation-provider
							#default="{ errors }"
							name="To Day"
							:rules="{
								required: true,
								integer: true,
								min_value: medicineScheduleData.from_day,
							}"
						>
							<b-form-input name="to_day" v-model="medicineScheduleData.to_day" placeholder="Enter To Day" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Medicine Type <span class="text-danger">*</span> </template>

						<validation-provider #default="{ errors }" name="Medicine Type" rules="required">
							<v-select
								v-model="medicineScheduleData.medicine_id"
								:options="medicineList"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Route Name <span class="text-danger">*</span> </template>

						<validation-provider #default="{ errors }" name="Route Name" rules="required">
							<v-select
								v-model="medicineScheduleData.route_type_id"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="routeList"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Dose Per 100 birds<span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Dose Per 100 birds" rules="required">
							<b-form-input name="dose" v-model="medicineScheduleData.dose" placeholder="Dose Per 100 birds" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>

		<!-- edit medicineSchedule -->
		<b-modal
			id="edit-medicine-schedule"
			ok-only
			ok-title="Submit"
			@ok="updateMedicine"
			ref="my-modal"
			title="Edit Medicine Schedule"
		>
			<validation-observer ref="simpleRules">
				<b-form ref="form" @submit="updateMedicine">
					<b-form class="" style="color: red">These changes would apply only to new flock registration. </b-form><br />
					<b-form-group>
						<template v-slot:label> Production Type <span class="text-danger">*</span> </template>

						<validation-provider #default="{ errors }" name="Production Type" rules="required">
							<v-select
								v-model="editMedicineData.production_type_id"
								:options="productionTypes"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group>
						<template v-slot:label> Enter From Day<span class="text-danger">*</span> </template>
						<validation-provider
							#default="{ errors }"
							name="From Day"
							:rules="{
								required: true,
								integer: true,
								min_value: 1,
							}"
						>
							<b-form-input name="from_day" v-model="editMedicineData.from_day" placeholder="" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter To Day<span class="text-danger">*</span> </template>
						<validation-provider
							#default="{ errors }"
							name="To Day"
							:rules="{
								required: true,
								integer: true,
								min_value: medicineScheduleData.from_day,
							}"
						>
							<b-form-input name="to_day" v-model="editMedicineData.to_day" placeholder="" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Medicine Name <span class="text-danger">*</span> </template>

						<validation-provider #default="{ errors }" name="name" rules="required">
							<v-select
								v-model="editMedicineData.medicine_id"
								:options="medicineList"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Route Name <span class="text-danger">*</span> </template>

						<validation-provider #default="{ errors }" name="Route Name" rules="required">
							<v-select
								v-model="editMedicineData.route_type_id"
								:options="routeList"
								label="name"
								clearable:false
								:reduce="(e) => e.id"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Dose Per 100 birds<span class="text-danger">*</span> </template>
						<validation-provider
							#default="{ errors }"
							name="From Day"
							:rules="{
								required: true,
								integer: true,
								min_value: 1,
							}"
						>
							<b-form-input name="from_day" v-model="editMedicineData.dose" placeholder="Enter From Day" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group label="Status" label-for=" status">
						<div class="d-flex mt-1">
							<b-form-radio v-model="editMedicineData.status" name="status" value="Active" class="mr-1"
								>Active</b-form-radio
							>
							<b-form-radio v-model="editMedicineData.status" name="status" value="Inactive">InActive</b-form-radio>
						</div>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
		<!-- delete modal -->
		<DeleteModal id="modal-delete-medicine" :onClicked="deleteMedicineSchedule" />
	</b-card>
</template>

<script>
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, alpha } from "@validations";

export default {
	components: {
		alpha,
		DeleteModal,
		vSelect,
	},
	data: () => ({
		medicineSchedules: [],
		routeList: [],
		medicineList: [],
		productionTypes: [],
		medicineScheduleData: {
			production_type_id: null,
			from_day: null,
			to_day: null,
			medicine_id: null,
			route_type_id: null,
			crude_protein: null,
			dose: null,
		},
		search_filter: {
			input_field: "",
			to_date: "",
			from_date: "",
		},
		editMedicineData: {
			from_day: null,
			to_day: null,
		},
		fields: [
			{ key: "index", label: "No." },
			{ key: "production_type" },
			{ key: "medicine", label: "medicine" },
			{ key: "route", label: "route" },
			"dose",
			{ key: "status", label: "Medicine status" },
			{
				key: "created_at",
				label: "created at",
				formatter: (value) => {
					return moment(value).format("MMMM Do YYYY");
				},
			},
			"Actions",
		],
	}),
	methods: {
		getMedicineSchedules(pageNo = 1) {
			let url = `web/medicine-schedule`;
			let queryParams = {
				page: pageNo,
				per_page: 2,
			};
			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}
			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.medicineSchedules = response.data.medicineScheduleList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getMedicineById(id) {
			axiosIns
				.get(`web/medicine-schedule/${id}`)
				.then((response) => {
					this.editMedicineData = response.data.medicineSchedule;
					this.editMedicineData.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		updateMedicine(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				var formData = new FormData();
				this.editMedicineData.method = "PUT";
				let form_data = this.objectToFormData(this.editMedicineData, formData);
				form_data.set("_method", "PUT");
				axiosIns
					.post(`web/medicine-schedule/${this.editMedicineData.id}`, form_data)
					.then((response) => {
						this.$toast({
							component: ToastificationContent,
							position: "top-right",
							props: {
								icon: "CoffeeIcon",
								variant: "success",
								text: `Medicine Schedule updated `,
							},
						});
						this.$refs["my-modal"].hide();
						this.getMedicineSchedules();
					})
					.catch((error) => {
						if (error.response.status == 400) {
							var data = error.response.data;
						} else {
							var data = error.response.data.errors;
						}
						let arr = Object.values(data).flat().toString(",");

						this.$toast({
							component: ToastificationContent,
							position: "top-right",
							props: {
								icon: "CoffeeIcon",
								variant: "danger",
								text: arr,
							},
						});
						this.$refs["my-modal"].hide();
					});
			});
		},
		addMedicineSchedule(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					let formData = new FormData();
					this.medicineScheduleData.farm_id = this.$route.params.farmId;
					this.medicineScheduleData.company_id = this.$route.params.companyId;
					let form_data = this.objectToFormData(this.medicineScheduleData, formData);

					axiosIns
						.post(`web/medicine-schedule`, form_data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.getMedicineSchedules();
							this.$refs["my-modal"].hide();
						})
						.catch((error) => {
							if (error.response.status == 400) {
								var data = error.response.data;
							} else {
								var data = error.response.data.errors;
							}
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
		deleteMedicineSchedule(id) {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/medicine-schedule/${this.editMedicineData.id}`, data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `Data Successfully Deleted `,
						},
					});
					this.$refs["my-modal"].hide();
					this.getMedicineSchedules();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.$refs["my-modal"].hide();
					this.getMedicineSchedules();
				});
		},
		getProductionTypes() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getRoute() {
			axiosIns
				.get(`web/routeType/vaccine`)
				.then((response) => {
					this.routeList = response.data.routeList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getMedicines() {
			axiosIns
				.get(`web/get-all-medicines`)
				.then((response) => {
					this.medicineList = response.data.medicine_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-vaccination-schedule`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getMedicineSchedules();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getMedicineSchedules();
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getMedicineSchedules();
		},
		resetModal() {
			this.medicineScheduleData.production_type_id = null;
			this.medicineScheduleData.medicine_id = null;
			this.medicineScheduleData.route_type_id = null;
			this.medicineScheduleData.crude_protein = null;
			this.medicineScheduleData.dose = null;
			this.medicineScheduleData.from_day = null;
			this.medicineScheduleData.to_day = null;
		},
	},

	created() {
		this.getMedicineSchedules();
		this.getRoute();
		this.getProductionTypes();
		this.getMedicines();
	},
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
	width: 90px;
}
.activeClass {
	background-color: #28c76f;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	border-radius: 10px;
	justify-content: center;
}
.inactiveClass {
	background-color: #ea5455;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	justify-content: center;
	border-radius: 10px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	// ::v-deep .vs__selected-options {
	// 	flex-wrap: nowrap;
	// }

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>