<template>
	<!-- Table Container Card -->
	<b-card no-body>
		<div class="m-2">
			<!-- Table Top -->
			<b-row class="d-flex justify-content-center align-items-center">
				<!-- Date picker -->
				<b-col cols="12 pr-2" md="6">
					<b-row>
						<b-col md="5 p-0">
							<label for="example-input">From Date</label>
							<b-form-datepicker id="from-date" :date-format-options="{
								year: 'numeric',
								month: 'short',
								day: '2-digit',
								weekday: 'short',
							}" placeholder="Choose a date" local="en" v-model="search_filter.from_date" />
						</b-col>
						<b-col md="5 p-0 ml-1 mr-1">
							<label for="example-input">To Date</label>
							<b-form-datepicker id="to-date" :date-format-options="{
								year: 'numeric',
								month: 'short',
								day: '2-digit',
								weekday: 'short',
							}" placeholder="Choose a date" local="en" v-model="search_filter.to_date" />
						</b-col>
						<b-col md="1 p-0 d-flex align-items-end">
							<b-button @click="getFeedingSchedule()" variant="primary "> Filter </b-button>
						</b-col>
					</b-row>
				</b-col>
				<!-- Search -->
				<b-col cols="12" md="6" class="pl-5 mt-2 d-flex justify-content-end align-items-center">
					<div>
						<b-form-input type="text" class="form-control" placeholder="Search"
							@input="getFeedingSchedule()" v-model.trim="search_filter.input_field"
							style="border-radius: 4px" />
					</div>
					<b-button @click="clearFilter" variant="secondary
 			 ml-1">
						Clear
					</b-button>
					<b-button v-b-modal.feed-schedule variant="primary mr-1 ml-1"> Add </b-button>
					<b-button @click="excelDownload()" variant="warning">
						<feather-icon icon="DownloadIcon" size="16" />
					</b-button>
				</b-col>
			</b-row>
		</div>
		<div v-if="feedScheduleList && feedScheduleList.total > 0">
			<b-table :items="feedScheduleList.data" responsive :fields="fields" show-empty
				empty-text="No matching records found" class="position-relative">
				<template #cell(index)="data">
					<div class="ml-1">
						{{ feedScheduleList.from + data.index }}
					</div>
				</template>
				<template #cell(production_type)="data">
					<span v-if="data.item.production_type">{{ data.item.production_type.name }}</span>
					<span v-else>Na</span>
				</template>
				<template #cell(strain)="data">
					<span v-if="data.item.strain">{{ data.item.strain.name }}</span>
					<span v-else>Na</span>
				</template>
				<template #cell(feed)="data">
					<span v-if="data.item.feed">{{ data.item.feed.name }}</span>
					<span v-else>Na</span>
				</template>
				<template #cell(feed_status)="data">
					<div v-if="data.value === 'Active'" class="activeClass">Active</div>
					<div v-else class="inactiveClass">Inactive</div>
				</template>
				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="text-nowrap">
						<feather-icon icon="EditIcon" size="16" v-b-modal.edit-feed-schedule
							@click="() => getFeedingById(data.item.id)" />

						<b-tooltip title="Edit" :target="`invoice-row-${data.item.id}-preview-icon`" />

						<!-- <feather-icon
              @click="() => setFeedingId(data.item.id)"
              icon="TrashIcon"
              size="16"
              class="mx-1"
              v-b-modal.modal-delete-feeding
            />

            <b-tooltip
              title="View"
              :target="`invoice-row-${data.item.id}-preview-icon`"
            /> -->
					</div>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-pagination :value="feedScheduleList.current_page" :total-rows="feedScheduleList.total"
					:per-page="feedScheduleList.per_page" align="right" @change="getFeedingSchedule">
					<template #prev-text>
						<feather-icon icon="ChevronLeftIcon" size="18" />
					</template>
					<template #next-text>
						<feather-icon icon="ChevronRightIcon" size="18" />
					</template>
				</b-pagination>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
			<h4 class="mt-2 Secondary">No Feed schedule added yet</h4>
		</div>
		<!--  modal -->
		<b-modal id="feed-schedule" ok-only ok-title="Submit" @ok="addFeedingSchedule" ref="my-modal"
			title="Add Feeding Schedule" @show="resetModal" @hidden="resetModal" :no-close-on-backdrop="true">
			<validation-observer ref="simpleRules">
				<b-form ref="form" @submit="addFeedingSchedule">
					<b-form-group>
						<template v-slot:label> Production Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Production Type" rules="required">
							<v-select v-model="feedingScheduleData.production_type_id" label="name"
								:options="productionTypes" clearable:false :reduce="(e) => e.id" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Strain Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="name" rules="required">
							<v-select v-model="feedingScheduleData.strain_id"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="strainList"
								clearable:false :reduce="(e) => e.id" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Feed Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Type" rules="required">
							<v-select v-model="feedingScheduleData.feed_type_id" label="name" :options="feedTypeList"
								clearable:false :reduce="(e) => e.id" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter From Day<span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="From Day" :rules="{
							required: true,
							integer: true,
							min_value: 1,
							max_value: 400,
						}">
							<b-form-input name="from_day" v-model="feedingScheduleData.from_day"
								placeholder="Enter From Day" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter To Day<span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="To Day" :rules="{
							required: true,
							integer: true,
							min_value: feedingScheduleData.from_day,
							max_value: 400,
						}">
							<b-form-input name="to_day" v-model="feedingScheduleData.to_day"
								placeholder="Enter To Day" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>

		<!-- edit-feedingSchedule -->
		<b-modal id="edit-feed-schedule" ok-only ok-title="Submit" @ok="updateFeeding" ref="my-modal"
			title="Edit Feeding Schedule" :no-close-on-backdrop="true">
			<validation-observer ref="simpleRules">
				<b-form ref="form" @submit="updateFeeding">
					<b-form class="" style="color: red">These changes would apply only to new flock registration.
					</b-form><br />
					<b-form-group>
						<template v-slot:label> Production Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Production Type">
							<v-select v-model="editFeedData.production_type_id" label="name" :options="productionTypes"
								clearable:false :reduce="(e) => e.id" disabled />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Strain Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Strain Type" rules="required">
							<v-select v-model="editFeedData.strain_id" label="name" :options="strainList"
								clearable:false :reduce="(e) => e.id" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Feed Type <span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="Type" rules="required">
							<v-select v-model="editFeedData.feed_type_id" label="name" :options="feedTypeList"
								clearable:false :reduce="(e) => e.id" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group>
						<template v-slot:label> Enter From Day<span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="From Day" :rules="{
							required: true,
							integer: true,
							min_value: 1 !== 0,
							max_value: 400,
						}">
							<b-form-input name="from_day" v-model="editFeedData.from_day" placeholder="" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<template v-slot:label> Enter To Day<span class="text-danger">*</span> </template>
						<validation-provider #default="{ errors }" name="To Day" :rules="{
							required: true,
							integer: true,
							min_value: feedingScheduleData.from_day,
							max_value: 400,
						}">
							<b-form-input name="to_day" v-model="editFeedData.to_day" placeholder="" />
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group label="Status" label-for=" status">
						<div class="d-flex mt-1">
							<b-form-radio v-model="editFeedData.feed_status" name="status" value="Active" class="mr-1">
								Active</b-form-radio>
							<b-form-radio v-model="editFeedData.feed_status" name="status" value="Inactive">InActive
							</b-form-radio>
						</div>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
		<!-- delete modal -->
		<DeleteModal id="modal-delete-feeding" :onClicked="deleteFeedingSchedule" />
	</b-card>
</template>

<script>
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DeleteModal from "@core/components/modal/Delete-modal.vue";
import { required, email, min_value, max_value, integer } from "@validations";
export default {
	components: {
		DeleteModal,
		vSelect,
	},
	data: () => ({
		value: "",
		selected: null,
		isSortDirDesc: null,
		searchQuery: null,
		sortBy: null,
		feed_status: null,
		productionTypes: [],
		strainList: [],
		feedTypeList: [],
		feedScheduleList: [],
		feedingScheduleData: {
			production_type_id: null,
			strain_id: null,
			feed_id: null,
			from_day: null,
			to_day: null,
		},
		search_filter: {
			input_field: "",
			to_date: "",
			from_date: "",
		},
		editFeedData: {
			to_day: null,
			from_day: null,
		},
		fields: [
			{ key: "index", label: "No" },
			{ key: "production_type" },
			{ key: "strain", label: "strain" },
			{ key: "feed_type.name", label: "feed type" },
			// "strain_id", // We required the Strain Name in this Field
			"from_day",
			"to_day",
			{ key: "feed_status", label: "feeding status" },
			{
				key: "created_at",
				label: "created at",
				formatter: (value) => {
					return moment(value).format("MMMM Do YYYY");
				},
			},
			"Actions",
		],
	}),
	methods: {
		getFeedingSchedule(pageNo = 1) {
			const farmId = this.$route.params.farmId;
			let url = "";
			if (farmId) {
				url = `web/farm/${farmId}/feed-schedule`;
			} else {
				url = `web/feed-schedule`;
			}
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}
			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.feedScheduleList = response.data.feedScheduleList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		setFeedingId(id) {
			this.editFeedData.id = id;
		},
		getProductionTypes() {
			axiosIns
				.get(`web/production-types`)
				.then((response) => {
					this.productionTypes = response.data.production_type.data;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFeedTypeList() {
			axiosIns
				.get(`web/feed-type`)
				.then((response) => {
					this.feedTypeList = response.data.feed_type;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getStrainList() {
			axiosIns
				.get(`web/strains`)
				.then((response) => {
					this.strainList = response.data.strain;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		getFeedingById(id) {
			axiosIns
				.get(`web/feed-schedule/${id}`)
				.then((response) => {
					this.editFeedData = response.data.feedSchedule;
					this.editFeedData.id = id;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
		updateFeeding(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				var formData = new FormData();
				this.editFeedData.name = this.editFeedData.name;
				this.editFeedData.method = "PUT";
				let form_data = this.objectToFormData(this.editFeedData, formData);
				form_data.set("_method", "PUT");
				axiosIns
					.post(`web/feed-schedule/${this.editFeedData.id}`, form_data)
					.then((response) => {
						this.$toast({
							component: ToastificationContent,
							position: "top-right",
							props: {
								icon: "CoffeeIcon",
								variant: "success",
								text: `Feeding Schedule updated `,
							},
						});
						this.$refs["my-modal"].hide();
						this.getFeedingSchedule();
					})
					.catch((error) => {
						if (error.response.status == 400) {
							var data = error.response.data;
						} else {
							var data = error.response.data.errors;
						}
						let arr = Object.values(data).flat().toString(",");

						this.$toast({
							component: ToastificationContent,
							position: "top-right",
							props: {
								icon: "CoffeeIcon",
								variant: "danger",
								text: arr,
							},
						});
					});
			});
		},
		addFeedingSchedule(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					let formData = new FormData();
					// this.feedingScheduleData.farm_id = this.$route.params.farmId;
					// this.feedingScheduleData.company_id = this.$route.params.companyId;
					let form_data = this.objectToFormData(this.feedingScheduleData, formData);

					axiosIns
						.post(`web/feed-schedule`, form_data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.getFeedingSchedule();
							this.$bvModal.hide("feed-schedule");
						})
						.catch((error) => {
							if (error.response.status == 400) {
								var data = error.response.data;
							} else {
								var data = error.response.data.errors;
							}
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},

		deleteFeedingSchedule(id) {
			var data = new FormData();
			data.append("_method", "DELETE");
			axiosIns
				.post(`web/feed-schedule/${this.editFeedData.id}`, data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: `Data Successfully Deleted `,
						},
					});
					this.$refs["my-modal"].hide();
					this.getFeedingSchedule();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
					this.$refs["my-modal"].hide();
					this.getFeedingSchedule();
				});
		},

		excelDownload() {
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-feed-schedule`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getFeedingSchedule();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getFeedingSchedule();
				});
		},
		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getFeedingSchedule();
		},
		resetModal() {
			this.feedingScheduleData.production_type_id = null;
			this.feedingScheduleData.strain_id = null;
			this.feedingScheduleData.feed_type_id = null;
			this.feedingScheduleData.from_day = null;
			this.feedingScheduleData.to_day = null;
		},
	},

	created() {
		this.getFeedingSchedule();
		this.getProductionTypes();
		this.getFeedTypeList();
		this.getStrainList();
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}

.activeClass {
	background-color: #28c76f;
	font-size: 10px;
	color: #ffffff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	border-radius: 10px;
	justify-content: center;
}

.inactiveClass {
	background-color: #ea5455;
	font-size: 10px;
	color: #fff;
	display: flex;
	align-items: center;
	width: 50px;
	height: 20px;
	justify-content: center;
	border-radius: 10px;
}

.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	// ::v-deep .vs__selected-options {
	// 	flex-wrap: nowrap;
	// }

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
