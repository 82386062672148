<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">Schedule</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- Table Container Card -->
    <b-card no-body>
      <b-tabs lazy class="m-2">
        <b-tab active>
          <template #title>
            <b-img
              :src="require('@/assets/images/svg/feed-schedule.svg')"
             style="margin-right: 7px; width: 20px"
            />
            <span>Feed Schedule</span>
          </template>
          <FeedingList />
        </b-tab>
        <b-tab>
          <template #title>
            <b-img
              :src="require('@/assets/images/svg/vaccine-schedule.svg')"
             style="margin-right: 7px; width: 20px"
            />
            <span>Vaccination Schedule</span>
          </template>
          <VaccinationList />
        </b-tab>
        <b-tab>
          <template #title>
            <b-img
              :src="require('@/assets/images/svg/medicine-schedule.svg')"
             style="margin-right: 7px; width: 20px"
            />
            <span>Medicine Schedule</span>
          </template>
          <MedicineSchedule />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>

import vSelect from "vue-select";
import FeedingList from "./FeedingList.vue";
import VaccinationList from "./VaccinationList.vue";
import MedicineSchedule from "./MedicineSchedule.vue";

export default {
  components: {
    vSelect,
    FeedingList,
    VaccinationList,
    MedicineSchedule,
  },
  data: () => ({
    selected: null,

    options: [
      { value: null, text: "" },
      { value: "a", text: "1" },
      { value: "b", text: "2" },
    ],
  }),
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
